import { Profile, ProfileState } from "@/types";
import ProfileRepository from "@/repositories/ProfileRepository";
import store from "@/store";

export const namespaced = true;

export const state: ProfileState = {
  profile: null
};

export const mutations = {
  SET(state: ProfileState, profile: Profile) {
    state.profile = profile;
  },

  CLEAR_STATE() {
    state.profile = null;
  }
};

export const actions = {
  async fetchProfile({ commit }: { commit: Function }) {
    const profile = await ProfileRepository.get();

    commit("SET", profile);

    store.dispatch("point/setYears", profile.regAt, { root: true });
  },

  clearState({ commit }: { commit: Function }) {
    commit("CLEAR_STATE");
  }
};
