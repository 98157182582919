import apiClient from "@/helpers/ApiHelper";

export default {
  async getProgress(): Promise<void | any> {
    const response: any = await apiClient.get("api/progress").catch(err => {
      // handle error
    });

    if (response && response.status === 200) {
      return response.data;
    }
  }
};
