import apiClient from "@/helpers/ApiHelper";

export default {
  async getProfile(): Promise<void | any> {
    const response: any = await apiClient
      .get("api/users/profile")
      .catch(err => {
        // handle error
      });

    if (response && response.status === 200) {
      return response.data;
    }
  }
};
