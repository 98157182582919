












































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Event, FreshEvent } from "@/types";
import Loader from "@/components/Loader.vue";
import EventRepository from "@/repositories/EventRepository";
import NotificationService from "@/services/NotificationService";

const { mapActions, mapState } = createNamespacedHelpers("event");

export default Vue.extend({
  data() {
    return {
      event: FreshEvent,
      submitting: false
    };
  },

  computed: {
    ...mapState(["status"]),

    action(): { enroll: boolean; unenroll: boolean } {
      return {
        enroll: this.$route.name === "event-enroll",
        unenroll: this.$route.name === "event-unenroll"
      };
    },

    hasExternallUrl(): boolean {
      return Boolean(this.event.registrationUrl);
    },

    externalEnrollment(): boolean {
      return this.event.organizerId !== 14;
    }
  },

  methods: {
    ...mapActions(["fetchEvent", "clearEvents", "fetchEvents"]),

    async onEnroll() {
      if (!this.hasExternallUrl) {
        this.submitting = true;
        const success = await EventRepository.enroll(this.event.id).catch(
          err => {
            this.submitting = false;
          }
        );

        if (success) {
          this.clearEvents();
          this.fetchEvents();
          this.$router.go(-1);
          NotificationService.addNotification(
            "success",
            "Вашу заявку на участь передано організатору заходу. Очікуйте на підтвердження від оператора."
          );
        }

        this.submitting = false;
      }
    },

    async onUnenroll() {
      this.submitting = true;
      const success = await EventRepository.unenroll(this.event.id).catch(
        err => {
          this.submitting = false;
        }
      );

      if (success) {
        this.clearEvents();
        this.fetchEvents();
        this.$router.go(-1);
        NotificationService.addNotification(
          "success",
          "Вашу заявку на участь у заході скасовано."
        );
      }

      this.submitting = false;
    }
  },

  async mounted() {
    this.event = await this.fetchEvent({ id: Number(this.$route.params.id) });
  },

  components: {
    Loader
  }
});
