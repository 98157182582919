











































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import store from "@/store";
import { ERAU_BASE_URL } from "@/config";
import { format } from "date-fns";
import Loader from "@/components/Loader.vue";
import FbButton from "@/components/FbButton.vue";
import GoogleButton from "@/components/GoogleButton.vue";

const { mapState, mapActions } = createNamespacedHelpers("profile");

export default Vue.extend({
  name: "Profile",

  props: {
    message: { type: String, default: "" },
    error: { type: Number, default: 0 }
  },

  computed: {
    ...mapState(["profile"]),

    facebookEmail(): string {
      let facebookAccount = this.profile.social_accounts.filter(
        (account: { type: Object }) => account.type === "facebook"
      )[0];
      return facebookAccount && facebookAccount.email
        ? facebookAccount.email
        : "";
    },

    googleEmail(): string {
      let googleAccount = this.profile.social_accounts.filter(
        (account: { type: Object }) => account.type === "google"
      )[0];
      return googleAccount && googleAccount.email ? googleAccount.email : "";
    },

    erauUrl(): string {
      return ERAU_BASE_URL + this.profile.erauId;
    },

    svidDate(): null | string {
      if (this.profile.svidDate)
        return format(this.profile.svidDate, "dd.MM.yyyy");
      return null;
    },

    regAt(): null | string {
      if (this.profile.regAt)
        return format(this.profile.regAt, "dd.MM.yyyy");
      return null;
    }
  },

  methods: {
    ...mapActions(["fetchProfile"]),

    logOut() {
      store.dispatch("account/logOut", { root: true });
      store.dispatch("event/clearState", { root: true });
      store.dispatch("notification/clearState", { root: true });
      store.dispatch("point/clearState", { root: true });
      store.dispatch("profile/clearState", { root: true });
    }
  },

  mounted() {
    if (!this.profile) {
      this.fetchProfile();
    }
  },

  components: {
    Loader,
    FbButton,
    GoogleButton
  }
});
