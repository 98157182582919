import { Point, PointState, Points } from "@/types";
import PointRepository from "@/repositories/PointRepository";
import { CURRENT_YEAR, POINTS_PER_PAGE, FIRST_YEAR } from "@/config";
import { getYear } from "date-fns";

export const namespaced = true;

export const state: PointState = {
  pointTotal: 0,
  perPage: POINTS_PER_PAGE,
  currentPage: 1,
  lastPage: 0,
  points: [],
  status: {
    loading: false,
    error: false
  },
  year: CURRENT_YEAR,
  years: [],
  fulfilled: false,
  certificateURL: "",
  progress: {
    current: {
      points: 0,
      event_points: 0,
      e_points: 0,
      b_points: 0
    },
    required: {
      points: 0,
      event_points: 0,
      e_points: 0,
      b_points: 0
    }
  }
};

export const mutations = {
  FETCH_POINTS_REQUEST() {
    state.status.loading = true;
  },

  FETCH_POINTS_FAIL() {
    state.status.error = true;
  },

  FETCH_POINTS_SUCCESS(state: PointState, payload: Points) {
    const {
      points,
      pointTotal,
      currentPage,
      lastPage,
      fulfilled,
      certificateURL,
      progress
    } = payload;

    const newIdSet = new Set(points.map(point => point.id));
    state.points = [
      ...(state.points as Point[]).filter(point => !newIdSet.has(point.id)),
      ...points
    ];

    state.pointTotal = pointTotal;
    state.currentPage = currentPage;
    state.lastPage = lastPage;
    state.fulfilled = fulfilled;
    state.certificateURL = certificateURL;
    state.progress = progress;
    state.status.loading = false;
    state.status.error = false;
  },

  CLEAR_POINTS() {
    state.points = [];
    state.pointTotal = 0;
    state.currentPage = 1;
    state.lastPage = 0;
  },

  SET_YEARS(state: PointState, years: number[]) {
    state.years = years;
  },

  SET_YEAR(state: PointState, year: number) {
    state.year = year;
  },

  CLEAR_STATE() {
    state.pointTotal = 0;
    state.perPage = POINTS_PER_PAGE;
    state.currentPage = 1;
    state.lastPage = 0;
    state.points = [];
    state.status = {
      loading: false,
      error: false
    };
    state.year = CURRENT_YEAR;
    state.years = [];
  }
};

export const actions = {
  async fetchPoints({ commit }: { commit: Function }, page = 1) {
    commit("FETCH_POINTS_REQUEST");

    const points = await PointRepository.getPoints(
      state.year,
      state.perPage,
      page
    ).catch(err => {
      commit("FETCH_POINTS_FAIL");
    });

    if (points) {
      commit("FETCH_POINTS_SUCCESS", points);
    }
  },

  setYear({ commit }: { commit: Function }, year: number) {
    commit("SET_YEAR", year);
    commit("CLEAR_POINTS");
  },

  setYears({ commit }: { commit: Function }, regAt: Date) {
    const svidYear = getYear(regAt);
    const firstYear = Math.max(svidYear, FIRST_YEAR);
    const arrayLen = Math.max(CURRENT_YEAR - firstYear + 1, 0);
    const years = new Array(arrayLen).fill(0).map((_, ind) => firstYear + ind);

    commit("SET_YEARS", years);
  },

  clearState({ commit }: { commit: Function }) {
    commit("CLEAR_STATE");
  }
};
