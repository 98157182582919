import { Profile } from "@/types";
import ProfileService from "@/services/ProfileService";
import { parseDate } from "@/helpers/DateHelper";

export default {
  async get(): Promise<Profile> {
    const data = await ProfileService.getProfile();
    const {
      name,
      surname,
      middlename,
      email,
      phone,
      bio,
      erau_id: erauId,
      svid_num: svidNum,
      svid_vid: svidVid,
      svid_date,
      racalc,
      reg_at,
      google_auth_link,
      facebook_auth_link,
      social_accounts,
      region_id: regionId
    } = data;

    const profile: Profile = {
      name,
      surname,
      middlename,
      email,
      phone,
      bio,
      erauId,
      svidNum,
      svidVid,
      svidDate: parseDate(svid_date),
      regAt: parseDate(reg_at),
      racalc,
      regionId,
      google_auth_link,
      facebook_auth_link,
      social_accounts
    };

    return profile;
  }
};
