<template>
  <a :href="url" class="facebook-button" :title="title">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.657 167.657">
      <path
        fill="#ffffff"
        d="M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154C167.657,37.881,130.125,0.349,83.829,0.349z"
      />
    </svg>
    <span v-html="name"></span>
  </a>
</template>

<script>
export default {
  name: "FbButton",

  props: {
    url: { type: String, required: true },
    title: { type: String, default: "Увійти через Facebook" },
    name: { type: String, default: "Увійти через Facebook" }
  }
};
</script>

<style lang="scss" scoped>
.facebook-button {
  background-color: #1877f2;
  border: 1px solid #1877f2;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 0.5rem 0.5rem;
  &:focus {
    outline: none;
  }
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
}
</style>
