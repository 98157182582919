import apiClient from "@/helpers/ApiHelper";
import store from "@/store";

export default {
  async postMessage(text: string, file?: File): Promise<boolean> {
    const formData = new FormData();
    if (file) {
      formData.append("image", file);
    }
    formData.append("text", text);

    const response: any = await apiClient
      .post("api/support", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        timeout: 20000,
        onUploadProgress(progressEvent) {
          const { loaded, total } = progressEvent;
          store.dispatch("support/setProgress", {
            progress: (loaded / total) * 100
          });
        }
      })
      .catch(err => {
        // handle error
      });

    if (response && response.status === 200) {
      return true;
    }

    return false;
  }
};
