import { Region, RegionState } from "@/types";
import RegionRepository from "@/repositories/RegionRepository";
import store from "@/store";

export const namespaced = true;

export const state: RegionState = {
  regions: []
};

export const mutations = {
  SET(state: RegionState, regions: Region[]) {
    state.regions = regions;
  }
};

export const actions = {
  async fetchRegions({ commit }: { commit: Function }) {
    const regions = await RegionRepository.getAll();
    commit("SET", regions);
  }
};
