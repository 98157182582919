




































import Vue from "vue";
import { QuestionResponse, ResponseOption } from "@/types";
import { CUSTOM_OPTION_TAG } from "@/config";

export default Vue.extend({
  data() {
    return {
      selected: [] as (number | null)[],
      text: "" as string
    };
  },

  props: {
    value: {
      type: Object
    },
    options: {
      type: Array as () => ResponseOption[],
      required: true
    },
    questionId: {
      type: Number,
      required: true
    }
  },

  computed: {
    hardOptions(): ResponseOption[] {
      return this.options.filter(option => option.text !== CUSTOM_OPTION_TAG);
    },

    customOptionId(): number | null {
      const customOption = this.options.find(
        option => option.text === CUSTOM_OPTION_TAG
      );

      return customOption ? customOption.id : null;
    },

    selectedHardOptions(): (number | null)[] {
      return this.selected.filter(optionId => optionId !== this.customOptionId);
    },

    selectedCustomOption(): (number | null)[] {
      return this.selected.includes(this.customOptionId)
        ? [this.customOptionId]
        : [];
    }
  },

  methods: {
    onOptionChange() {
      this.$emit("input", this.getValueToEmit());
    },

    onTextChange(val: string) {
      this.text = val;
      this.$emit("input", this.getValueToEmit());
    },

    getValueToEmit() {
      return {
        optionIds: [...this.selectedHardOptions, ...this.selectedCustomOption],
        ...(this.text.length > 0
          ? { text: this.text, customOptionId: this.customOptionId }
          : {}),
        questionId: this.questionId
      };
    }
  },

  mounted() {
    this.selected = this.value.optionIds ? this.value.optionIds : [];
    this.text = this.value.text ? this.value.text : "";
  }
});
