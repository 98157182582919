



















































import { Question, QuizType } from "@/types";
import Vue from "vue";

export default Vue.extend({
  props: {
    quizType: { type: String as () => QuizType, required: true },
    passed: { type: Boolean, required: true },
    message: { type: String, default: "" as string },
    questionIds: { type: Array, default: [] as number[] },
    questions: { type: Array, default: [] as Question[] }
  },

  computed: {
    failedQuestions(): { text: string; id: number; ind: number }[] {
      return (this.questions as Question[])
        .map(({ text, id }, ind) => ({ text, id, ind }))
        .filter(question => this.questionIds.includes(question.id));
    }
  }
});
