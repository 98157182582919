












import Vue from "vue";
import { QuestionResponse, ResponseOption } from "@/types";
import { CUSTOM_OPTION_TAG } from "@/config";

export default Vue.extend({
  data() {
    return {
      text: ""
    };
  },

  props: {
    value: {
      type: Object
    },
    options: {
      type: Array as () => ResponseOption[],
      required: true
    },
    questionId: {
      type: Number,
      required: true
    }
  },

  computed: {
    optionId(): number {
      return this.options[0].id;
    }
  },

  methods: {
    onTextChange(val: string) {
      this.text = val;
      this.$emit("input", {
        optionIds: [this.optionId],
        text: this.text,
        customOptionId: this.optionId,
        questionId: this.questionId
      } as QuestionResponse);
    }
  },

  mounted() {
    this.text = this.value && this.value.text ? this.value.text : "";
  }
});
