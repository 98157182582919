import apiClient from "@/helpers/ApiHelper";
import { CURRENT_YEAR, POINTS_PER_PAGE } from "@/config";

export default {
  async getPoints(
    year: number = CURRENT_YEAR,
    perPage: number = POINTS_PER_PAGE,
    page: number
  ) {
    const response: any = await apiClient
      .get("api/points", { params: { page, year, perpage: perPage } })
      .catch(err => {
        // handle error
      });

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  }
};
