import { Notification, NotificationState } from "@/types";
import { NOTIFICATION_LIFE_SPAN } from "@/config";

export const namespaced = true;

export const state: NotificationState = {
  notifications: []
};

let nextId = 1;

export const mutations = {
  PUSH(state: NotificationState, notification: Notification) {
    state.notifications.push(notification);
  },

  DELETE(state: NotificationState, notificationId: number) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationId
    );
  },

  CLEAR_STATE() {
    state.notifications = [];
  }
};

export const actions = {
  add({ commit }: { commit: Function }, notification: Notification) {
    const id = nextId;
    const timeoutId = setTimeout(() => {
      commit("DELETE", id);
    }, NOTIFICATION_LIFE_SPAN);

    commit("PUSH", { ...notification, id, timeoutId });
    nextId += 1;
  },

  remove({ commit }: { commit: Function }, notificationId: number) {
    const notification = state.notifications.find(
      notification => notification.id === notificationId
    );

    if (notification) {
      clearTimeout(notification.timeoutId);
    }

    commit("DELETE", notificationId);
  },

  clearState({ commit }: { commit: Function }) {
    commit("CLEAR_STATE");
  }
};
