


























import Vue from "vue";
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false
    },
    okBtnText: {
      type: String,
      required: true
    },
    cancelBtnText: {
      type: String,
      required: false
    }
  }
});
