import apiClient from "@/helpers/ApiHelper";

export default {
  async getRegions(): Promise<void | any> {
    const response: any = await apiClient.get("api/regions").catch(err => {
      // handle error
    });

    if (response && response.status === 200) {
      return response.data;
    }
  }
};
