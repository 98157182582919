export interface AccountState {
  status: {
    loggedIn?: boolean;
    emailSubmitted?: boolean;
    phoneSubmitted?: boolean;
    codeSubmitted?: boolean;
    smsSent?: boolean;
  };
  accessToken?: string;
  refreshToken?: string;
  phone?: string;
}

export interface Notification {
  id: number;
  type: string;
  message: string;
  timeoutId: number;
}

export interface NotificationState {
  notifications: Notification[];
}

export interface Profile {
  name: string;
  surname: string;
  middlename?: string;
  email: string;
  phone: number | null;
  bio: string;
  erauId: string;
  svidNum: string;
  svidVid: string;
  svidDate: Date;
  regAt: Date;
  racalc: string;
  regionId: number | null;
  google_auth_link: string;
  facebook_auth_link: string;
  social_accounts: any;
}

export interface ProfileState {
  profile: Profile | null;
}

export type ExamStatus = null | "passed" | "failed" | "none";

export type FeedbackStatus = null | "passed" | "none";

export interface EventServerResponse {
  id: any;
  name: any;
  type: any;
  place: any;
  points: any;
  e_point: any;
  b_points: any;
  start: any;
  end: any;
  plan: any;
  registration_url: any;
  region_name: any;
  exam_status: any;
  feedback_status: any;
  qr_url: any;
  join_url: any;
  approved: any;
  organizer_name: any;
  organizer_id: any;
  attended: any;
  is_active: any;
}
export interface Event {
  id: number;
  title: string;
  type?: EventType;
  points: number;
  ePoints: number;
  bPoints: number;
  start: Date;
  end: Date;
  plan: {
    themeName: string;
    lectorFio: string;
  }[];
  location: string;
  registrationUrl: string;
  regionName: string;
  examStatus?: ExamStatus;
  feedbackStatus?: FeedbackStatus;
  qrUrl?: string;
  joinUrl?: string;
  approved?: null | 1 | 0;
  attended?: null | 1 | 0;
  organizerName: string;
  organizerId: number;
  isActive: boolean;
}
export interface EventState {
  eventsType: EventsType;
  perPage: number;
  events: Event[];
  event?: Event | null;
  status: {
    loading?: boolean;
    error?: boolean;
  };
  currentPage: number;
  lastPage: number;
  eventTotal: number;
  windowTop: number;
  showFilter: boolean;
  filter: EventFilter;
  dates: string[];
}

export type EventsType = "active" | "enrolled" | "passed" | "";
export type EventType = "offline" | "online" | "online_course";

export interface Events {
  events: Event[];
  eventTotal: number;
  currentPage: number;
  lastPage: number;
  type: EventsType;
}

export interface EventFilter {
  paid?: boolean | null;
  online?: boolean | null;
  search?: string | null;
  date?: string | null;
  region?: number | null;
  anytime?: boolean;
}

export interface Point {
  id: number;
  reason: string;
  poits: number;
  ePoints: number;
  bPoints: number;
  added: string;
}

export interface Points {
  points: Point[];
  pointTotal: number;
  currentPage: number;
  lastPage: number;
  fulfilled: boolean;
  certificateURL: string;
  progress: {
    current: {
      points: number;
      event_points: number;
      e_points: number;
      b_points: number;
    };
    required: {
      points: number;
      event_points: number;
      e_points: number;
      b_points: number;
    };
  };
}

export interface PointState {
  pointTotal: number;
  perPage: number;
  points: Point[] | null;
  status: {
    loading?: boolean;
    error?: boolean;
  };
  currentPage: number;
  lastPage: number;
  year: number;
  years: number[];
  fulfilled: boolean;
  certificateURL: string;
  progress: {
    current: {
      points: number;
      event_points: number;
      e_points: number;
      b_points: number;
    };
    required: {
      points: number;
      event_points: number;
      e_points: number;
      b_points: number;
    };
  };
}

export type Resource = "Захід" | "Сторінка";

export interface QuestionResponse {
  questionId: number;
  optionIds: number[];
  text?: string;
  customOptionId?: number;
}

export interface ResponseOption {
  id: number;
  text: string;
}

export interface NavItem {
  path: string;
  title: string;
  label: string;
  icon: string;
  fullHeader?: boolean;
}

export interface SupportState {
  text: string;
  status: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  progress: number | null;
}

export type QuizType = "exam" | "feedback";
export type QuestionType = "single" | "multiple" | "essay";

export interface Question {
  id: number;
  type: QuestionType;
  text: string;
  options: ResponseOption[];
}

export type EventCardStatus =
  | "showQr"
  | "closed"
  | "enrolling"
  | "applied"
  | "confirmed"
  | "examFailed"
  | "awarded";

export type EventCardActions =
  | "enroll"
  | "unenroll"
  | "qrcode"
  | "exam"
  | "feedback"
  | "join";

export const FreshEvent = ({
  id: 0,
  title: "",
  type: null,
  points: 0,
  ePoints: 0,
  bPoints: 0,
  start: new Date(),
  end: new Date(),
  plan: [],
  location: "",
  registrationUrl: "",
  regionName: "",
  examStatus: null,
  feedbackStatus: null,
  qrUrl: "",
  approved: null,
  organizerId: 0
} as unknown) as Event;

export interface RefusedFeedback {
  id: number;
  end: Date;
}

export interface DashboardState {
  refusedFeedbacks: RefusedFeedback[];
}

export interface Progress {
  current: {
    points: number;
    e_points: number;
    b_points: number;
  };
  required: {
    points: number;
    e_points: number;
    b_points: number;
  };
}

export interface Region {
  id: number;
  name: string;
}

export interface RegionState {
  regions: Region[];
}

export interface Quote {
  id: number;
  text: string | null;
  img: string | null;
  url: string | null;
}

export interface ExamResult {
  passed: boolean;
  failedQuestionIds: number[];
  message: string;
}

type Values<T> = T[keyof T];

export const LoginProviders = {
  fb: "fb",
  google: "google"
} as const;

export type LoginProvider = Values<typeof LoginProviders>;
