import apiClient from "@/helpers/ApiHelper";
import NotificationService from "@/services/NotificationService";
import { EventsType, EventFilter } from "@/types";

export default {
  async getEvents(
    eventsType: EventsType,
    perPage: number,
    page: number,
    filter: EventFilter
  ) {
    const response: any = await apiClient
      .get("api/events", {
        params: { page, status: eventsType, perpage: perPage, ...filter }
      })
      .catch(err => {
        // handle error
      });

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.data
    ) {
      return response.data;
    }
  },

  async getEvent(eventId: number) {
    const response: any = await apiClient
      .get(`api/events/${eventId}`)
      .catch(err => {
        // handle error
      });

    return response.data;
  },

  async enroll(eventId: number): Promise<boolean> {
    const response: any = await apiClient
      .post(`api/events/${eventId}`, { action: "register" })
      .catch(err => {
        NotificationService.addNotification("error", err.response.data.message);
      });

    if (response) {
      return true;
    }

    return false;
  },

  async unenroll(eventId: number): Promise<boolean> {
    const response: any = await apiClient
      .post(`api/events/${eventId}`, { action: "cancel" })
      .catch(err => {
        NotificationService.addNotification("error", err.response.data.message);
      });

    if (response) {
      return true;
    }

    return false;
  },

  async getAllEventsDates() {
    const response: any = await apiClient.get(`api/all-dates`).catch(err => {
      // handle error
    });

    return response.data;
  }
};
