import axios, { AxiosResponse } from "axios";
import router from "@/router";
import LocalStorageService from "@/services/LocalStorageService";
import NotificationService from "@/services/NotificationService";
import { API_BASE_URL } from "@/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});

apiClient.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(undefined, error => {
  const originalRequest = error.config;
  const requestData = originalRequest.data
    ? JSON.parse(originalRequest.data)
    : {};

  if (!!error.isAxiosError && !error.response) {
    NotificationService.addNotification(
      "error",
      "Перевірте, будь ласка, з'єднання з Інтернетом"
    );
  }

  if (error.response.status === 500) {
    NotificationService.addNotification(
      "error",
      "Щось пішло не так на наших серверах. Спробуйте, будь ласка, пізніше або зверніться до підтримки."
    );
    return Promise.reject(error);
  }

  if (
    error.response.status === 401 &&
    originalRequest.url === "/oauth/token" &&
    requestData["grant_type"] === "refresh_token"
  ) {
    LocalStorageService.clearTokens();
    router.push("/login");
    return Promise.reject(error);
  }

  if (
    error.response.status === 401 &&
    (originalRequest.url === "/api/code" ||
      requestData["grant_type"] === "sms_grant")
  ) {
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = LocalStorageService.getRefreshToken();

    return apiClient
      .post("/oauth/token", {
        refresh_token: refreshToken,
        grant_type: "refresh_token"
      })
      .then(response => {
        if (response && response.status === 200 && response.data) {
          const {
            access_token: accessToken,
            refresh_token: refreshToken
          } = response.data;

          LocalStorageService.setTokens(accessToken, refreshToken);
          originalRequest.headers["Authorization"] = "Bearer " + accessToken;

          return apiClient(originalRequest);
        } else {
          return response;
        }
      })
      .catch(() => {});
  }

  return Promise.reject(error);
});

export default apiClient;
