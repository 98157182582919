




import Vue from "vue";

import { createNamespacedHelpers } from "vuex";
import { Region } from "@/types";

const { mapState, mapActions } = createNamespacedHelpers("region");

export default Vue.extend({
  props: {
    value: { type: Number, default: 0 }
  },

  computed: {
    ...mapState(["regions"]),

    items(): { text: string; value: number }[] {
      return [
        { text: "Всі регіони", value: null },
        ...this.regions.map((region: Region) => ({
          text: region.name,
          value: region.id
        }))
      ];
    }
  },

  methods: {
    ...mapActions(["fetchRegions"])
  },

  mounted() {
    if (this.regions.length === 0) {
      this.fetchRegions();
    }
  }
});
