import { parse, isValid, addDays, isAfter } from "date-fns";
import { DB_DATE_FORMATS, STALE_DAYS } from "@/config";

export const parseDate = (dateStr: string | any): Date => {
  if (typeof dateStr === "object" && dateStr.date) {
    try {
      const regex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/g;
      const parsed = Array.from(dateStr.date.matchAll(regex))[0] as number[];
      const [str, year, month, day, hour, minute, second] = parsed;
      return new Date(year, month - 1, day, hour, minute, second);
    } catch (e) {
      //
    }
  }

  const format = DB_DATE_FORMATS.find(formatStr =>
    isValid(parse(dateStr, formatStr, new Date()))
  );

  if (format) {
    return parse(dateStr, format, new Date());
  }

  return new Date(NaN);
};

export const isFeedbackDateExpired = (date: Date) =>
  isAfter(new Date(), addDays(date, STALE_DAYS));
