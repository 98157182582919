













import Vue from "vue";
import { Event, FreshEvent } from "@/types";
import EventCard from "@/components/EventCard.vue";
import Loader from "@/components/Loader.vue";
import EventRepository from "@/repositories/EventRepository";
import router from "@/router";
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapState } = createNamespacedHelpers("event");

export default Vue.extend({
  data() {
    return {
      event: FreshEvent
    };
  },

  computed: {
    ...mapState(["status"])
  },

  methods: {
    ...mapActions(["fetchEvent"])
  },

  async mounted() {
    this.fetchEvent({ id: Number(this.$route.params.id) }).then(
      event => (this.event = event)
    );
  },

  components: {
    EventCard,
    Loader
  }
});
