import EventService from "@/services/EventService";
import {
  Event,
  EventsType,
  Events,
  EventFilter,
  EventServerResponse
} from "@/types";
import { parseDate } from "@/helpers/DateHelper";

const planMapper = (block: { theme_name: any; lector_fio: any }) => {
  const { theme_name, lector_fio } = block;

  return {
    themeName: theme_name,
    lectorFio: lector_fio
  };
};

const eventMapper = (event: EventServerResponse): Event => {
  const {
    id,
    name: title,
    type,
    points,
    e_point: ePoints,
    b_points: bPoints,
    start,
    end,
    plan,
    place: location,
    registration_url: registrationUrl,
    region_name: regionName,
    exam_status: examStatus,
    feedback_status: feedbackStatus,
    qr_url: qrUrl,
    join_url: joinUrl,
    approved,
    organizer_name,
    organizer_id,
    attended,
    is_active
  } = event;

  const parsedPlan = plan.map(planMapper);

  return {
    id,
    title,
    type,
    points,
    ePoints: Number(ePoints),
    bPoints: Number(bPoints),
    start: parseDate(start),
    end: parseDate(end),
    plan: parsedPlan,
    location,
    registrationUrl,
    regionName,
    examStatus,
    feedbackStatus,
    qrUrl,
    joinUrl,
    approved,
    attended,
    organizerName: organizer_name,
    organizerId: organizer_id,
    isActive: Boolean(is_active)
  };
};

export default {
  async getEventById(eventId: number): Promise<Event> {
    const event = await EventService.getEvent(eventId);

    return eventMapper(event);
  },

  async getEvents(
    eventsType: EventsType,
    perPage: number,
    page: number,
    filter: EventFilter
  ): Promise<Events> {
    const data = await EventService.getEvents(
      eventsType,
      perPage,
      page,
      filter
    );

    const events = data.data.map(eventMapper);

    const {
      total: eventTotal,
      current_page: currentPage,
      last_page: lastPage
    } = data;

    return { events, eventTotal, currentPage, lastPage, type: eventsType };
  },

  async enroll(eventId: number): Promise<boolean> {
    return await EventService.enroll(eventId);
  },

  async unenroll(eventId: number): Promise<boolean> {
    return await EventService.unenroll(eventId);
  },

  async getAllEventsDates(): Promise<string[]> {
    return await EventService.getAllEventsDates();
  }
};
