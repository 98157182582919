import apiClient from "@/helpers/ApiHelper";
import { AxiosResponse } from "axios";
import NotificationService from "@/services/NotificationService";
import store from "@/store";

export default {
  async logInEmail(
    email: string,
    password: string
  ): Promise<void | AxiosResponse<any>> {
    const response = await apiClient
      .post(`/oauth/token`, {
        grant_type: "password",
        username: email,
        password
      })
      .catch(err => {
        NotificationService.addNotification(
          "error",
          "Профіля адвоката з таким e-mail або паролем не існує"
        );
        store.dispatch("account/resetFormToEmail", { root: true });
      });
    return response;
  },

  async logIn(phone: string): Promise<void | AxiosResponse<any>> {
    const response = await apiClient
      .post(`/api/code`, {
        phone
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          NotificationService.addNotification(
            "error",
            "Профіля адвоката з таким телефоном не існує"
          );
        }

        store.dispatch("account/resetFormToPhone", { root: true });
      });
    return response;
  },

  async getToken(code: string): Promise<void | AxiosResponse<any>> {
    const response = await apiClient
      .post(`/oauth/token`, {
        code,
        grant_type: "sms_grant"
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          NotificationService.addNotification("error", "Код не вірний");
        }

        store.dispatch("account/resetFormToCode", { root: true });
      });

    return response;
  }
};
