import apiClient from "@/helpers/ApiHelper";

export default {
  async getQuotes() {
    const response: any = await apiClient.get("api/quotes").catch(err => {
      // handle error
    });

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  },

  async markAsViewed(id: number) {
    await apiClient.post(`api/quotes/${id}`).catch(err => {
      // handle error
    });
  }
};
