import PointService from "@/services/PointService";
import { Point, Points } from "@/types";

export default {
  async getPoints(year: number, limit: number, page: number): Promise<Points> {
    const data = await PointService.getPoints(year, limit, page);

    const { requirements, pointsData, progress } = data;
    const { fulfilled, certificate } = requirements[year];

    const points = pointsData.data.map(
      (pointRecord: {
        id: any;
        reason: any;
        points: any;
        e_points: any;
        b_points: any;
        added: any;
      }) => {
        const {
          id,
          reason,
          points,
          e_points: ePoints,
          b_points: bPoints,
          added
        } = pointRecord;

        return {
          id,
          reason,
          points,
          ePoints,
          bPoints,
          added
        };
      }
    );

    const {
      total: pointTotal,
      current_page: currentPage,
      last_page: lastPage
    } = pointsData;

    return {
      points,
      pointTotal,
      currentPage,
      lastPage,
      fulfilled: Boolean(fulfilled),
      certificateURL: certificate,
      progress: progress
    };
  }
};
