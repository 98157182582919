import ProgressService from "@/services/ProgressService";
import { Progress } from "@/types";

export default {
  async getProgress(): Promise<Progress> {
    const progress = await ProgressService.getProgress();

    return progress;
  }
};
