
































































































import Vue from "vue";

export default Vue.extend({
  computed: {
    quizType(): { exam: boolean; feedback: boolean } {
      return {
        exam: this.$route.name === "exam-start",
        feedback: this.$route.name === "feedback-start"
      };
    }
  }
});
