
























































import Vue from "vue";
import { NAV_ITEMS } from "@/config";
import { mapState } from "vuex";
import { CURRENT_YEAR } from "@/config";
import { NavItem } from "@/types";
import EventFilter from "@/components/EventFilter.vue";

export default Vue.extend({
  data() {
    const { year } = this.$store.state.point;
    return {
      year,
      previousYear: year,
      filterHeight: 0
    };
  },

  computed: {
    navItem(): NavItem | undefined {
      return NAV_ITEMS.find(item => item.path === this.$route.path);
    },

    fullHeader(): boolean {
      const navItem = this.navItem as NavItem;
      return Boolean(navItem && navItem.fullHeader);
    },

    title(): string {
      const navItem = this.navItem as NavItem;
      return navItem ? navItem.title : "Повернутися";
    },

    pointsViewOnly(): boolean {
      return this.$route.path === "/points";
    },

    eventsViewOnly(): boolean {
      return this.$route.path === "/events";
    },

    ...mapState("point", ["years"]),
    ...mapState("event", ["showFilter", "filter"])
  },

  watch: {
    showFilter(newValue, oldValue) {
      this.setFilterHeight();
    },
    filter(newValue, oldValue) {
      this.setFilterHeight();
    }
  },

  methods: {
    onYearChanged() {
      const { year, previousYear } = this;

      if (year === previousYear) {
        return;
      }

      this.previousYear = year;

      this.$store.dispatch("point/setYear", year);
      this.$store.dispatch("point/fetchPoints");
    },

    setFilterHeight() {
      const filterElement = this.$refs.filterElement as HTMLElement;
      if (filterElement) {
        this.$nextTick().then(() => {
          this.filterHeight = filterElement.clientHeight;
        });
      }
    }
  },

  mounted() {
    this.setFilterHeight();
  },
  components: {
    EventFilter
  }
});
