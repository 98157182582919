








































































import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { EventFilter, Region } from "@/types";
import RegionSelectInput from "@/components/RegionSelectInput.vue";
import DatePickInput from "@/components/DatePickInput.vue";
import { format } from "date-fns";

const TODAY = format(new Date(), "yyy-MM-dd");

export default Vue.extend({
  data() {
    return {
      paid: 0,
      online: 0,
      anytime: 0,
      region: null as number | null,
      date: TODAY,
      search: ""
    };
  },

  computed: {
    ...mapState("event", ["showFilter", "filter"]),
    ...mapState("region", ["regions"]),
    ...mapGetters("event", ["filterNotEmty"]),

    regionName(): string {
      const region = (this.regions as Region[]).find(
        region => region.id === this.region
      );

      return region ? region.name : "";
    },

    showChips(): boolean {
      this.$emit("change-height");
      return this.filterNotEmty && !this.showFilter;
    },

    chips(): { text: string; closeAction: Function; icon: string }[] {
      return [
        ...(this.anytime === 1
          ? [
              {
                text: "Постійні",
                closeAction: this.toggleAnytime,
                icon: "mdi-swap-horizontal-circle"
              }
            ]
          : [
              {
                text: "Поточні",
                closeAction: this.toggleAnytime,
                icon: "mdi-swap-horizontal-circle"
              }
            ]),
        ...(this.paid === 1
          ? [
              {
                text: "Платні",
                closeAction: this.clearPaid,
                icon: "mdi-close-circle"
              }
            ]
          : []),
        ...(this.paid === 2
          ? [
              {
                text: "Безоплатні",
                closeAction: this.clearPaid,
                icon: "mdi-close-circle"
              }
            ]
          : []),
        ...(this.online === 1
          ? [
              {
                text: "Офлайн",
                closeAction: this.clearOnline,
                icon: "mdi-close-circle"
              }
            ]
          : []),
        ...(this.online === 2
          ? [
              {
                text: "Онлайн",
                closeAction: this.clearOnline,
                icon: "mdi-close-circle"
              }
            ]
          : []),
        ...(this.search
          ? [
              {
                text:
                  this.search.length < 40
                    ? this.search
                    : this.search.substring(0, 40) + "...",
                closeAction: this.clearSearch,
                icon: "mdi-close-circle"
              }
            ]
          : []),
        ...(this.date && this.date >= TODAY
          ? [
              {
                text: format(new Date(this.date), "dd.MM.yyy"),
                closeAction: this.clearDate,
                icon: "mdi-close-circle"
              }
            ]
          : []),
        ...(this.region
          ? [
              {
                text: this.regionName,
                closeAction: this.clearRegion,
                icon: "mdi-close-circle"
              }
            ]
          : [])
      ];
    }
  },

  watch: {
    showFilter(newValue, oldValue) {
      if (newValue === false) {
        this.setValuesFromStore();
      }
    },

    anytime(newValue, oldValue) {
      if (newValue) {
        this.region = null;
        this.date = "";
        this.online = 0;
      } else {
        this.date = "";
      }
    }
  },

  methods: {
    ...mapActions("event", [
      "setFilter",
      "toggleFilter",
      "clearState",
      "fetchEvents",
      "clearEvents",
      "fetchAllDates"
    ]),

    toggleAnytime() {
      this.anytime = 1 - this.anytime;
      this.onSetFilter();
      this.$emit("change-height");
    },

    clearPaid() {
      this.paid = 0;
      this.onSetFilter();
    },

    clearOnline() {
      this.online = 0;
      this.onSetFilter();
    },

    clearSearch() {
      this.search = "";
      this.onSetFilter();
    },

    clearDate() {
      this.date = "";
      this.onSetFilter();
    },

    clearRegion() {
      this.region = null;
      this.onSetFilter();
    },

    getFilterValue(): EventFilter {
      const anytime = Boolean(this.anytime);
      const paid = this.paid === 0 ? null : this.paid === 1;
      const online = this.online === 0 ? null : this.online === 2;
      const search = this.search ? this.search : null;
      const date = this.date ? this.date : null;
      const region = this.region ? this.region : null;

      const filter = {
        anytime,
        paid,
        online,
        search,
        date,
        region
      };

      return filter;
    },

    setValuesFromStore() {
      this.paid = this.filter.paid === null ? 0 : this.filter.paid ? 1 : 2;
      this.online =
        this.filter.online === null ? 0 : this.filter.online ? 2 : 1;
      this.search = this.filter.search ? this.filter.search : "";
      this.date = this.filter.date ? this.filter.date : "";
      this.region = this.filter.region ? this.filter.region : null;
      this.anytime = this.filter.anytime ? 1 : 0;
    },

    onSetFilter() {
      if (this.search) {
        this.search = this.search.trim();
      }
      const newFilter = this.getFilterValue();
      if (
        newFilter.paid !== this.filter.paid ||
        newFilter.online !== this.filter.online ||
        newFilter.search !== this.filter.search ||
        newFilter.anytime !== this.filter.anytime ||
        newFilter.region !== this.filter.region ||
        newFilter.date !== this.filter.date
      ) {
        this.setFilter(newFilter);
        this.clearEvents();
        this.fetchEvents();
      } else {
        this.toggleFilter();
      }
    },

    onKeyDown(ev: KeyboardEvent) {
      if (ev.key === "Enter") {
        this.onSetFilter();
      }
    }
  },

  mounted() {
    this.setValuesFromStore();
    this.fetchAllDates();
  },

  components: {
    RegionSelectInput,
    DatePickInput
  }
});
