

































































import Vue from "vue";
import QuestionInput from "@/components/QuestionInput.vue";
import QuizResult from "@/components/QuizResult.vue";
import { Question, QuestionResponse, QuizType } from "@/types";
import Loader from "@/components/Loader.vue";
import QuizSubmit from "@/components/QuizSubmit.vue";
import { CUSTOM_OPTION_TAG, TEXT_TAG } from "@/config";
import QuizRepository from "@/repositories/QuizRepository";
import NotificationService from "@/services/NotificationService";

export default Vue.extend({
  props: {
    quizType: { type: String as () => QuizType, required: true }
  },

  data() {
    return {
      eventId: 0,
      questions: [] as Question[],
      responses: [] as QuestionResponse[],
      currentQuestion: 0,
      readyToSubmit: false,
      status: {
        loading: false,
        error: false,
        submit: false,
        submitting: false,
        result: false
      },
      examResult: {}
    };
  },

  computed: {
    question(): Object {
      window.scrollTo(0, 0);
      return this.questions[this.currentQuestion];
    },
    questionId(): number {
      return this.questions[this.currentQuestion].id;
    },
    isFirstQuestion(): boolean {
      return this.currentQuestion === 0;
    },
    isLastQuestion(): boolean {
      return this.currentQuestion === this.questions.length - 1;
    },
    isExam(): boolean {
      return this.quizType === "exam";
    },
    isFeedback(): boolean {
      return this.quizType === "feedback";
    }
  },

  methods: {
    nextQuestion() {
      if (!this.isLastQuestion) {
        this.currentQuestion += 1;
      }
    },

    previousQuestion() {
      if (!this.isFirstQuestion) {
        this.currentQuestion -= 1;
      }
    },

    onSubmit() {
      if (!this.responsesAreValid()) {
        NotificationService.addNotification(
          "error",
          "Схоже, що Ви залишили якесь питання без відповіді. Перевірте, будь-ласка!"
        );
        return;
      }

      if (this.isExam) {
        this.status.submit = true;
      } else {
        this.submitResponses();
      }
    },

    async submitResponses() {
      this.status.submitting = true;
      this.examResult = await QuizRepository.addResponse(
        this.quizType,
        this.eventId,
        this.responses
      );
      this.status.submitting = false;
      this.status.submit = false;
      this.status.result = true;
    },

    responsesAreValid(): boolean {
      return !this.responses.some(response => response.optionIds.length === 0);
    },

    onRetake() {
      (this.responses = [] as QuestionResponse[]),
        (this.currentQuestion = 0),
        (this.readyToSubmit = false),
        (this.status = {
          loading: false,
          error: false,
          submit: false,
          submitting: false,
          result: false
        }),
        (this.examResult = {});
    },

    onReturn() {
      this.$store.dispatch("event/clearEvents");
      this.$store.dispatch("event/fetchEvents");
      this.$router.go(-1);
    }
  },

  async mounted() {
    this.status.loading = true;
    this.eventId = Number(this.$route.params.id);

    const questions = await QuizRepository.getByTypeAndId(
      this.quizType,
      this.eventId
    );
    if (questions) {
      this.questions = questions;
      this.responses = this.questions.map(question => ({
        questionId: question.id,
        optionIds: [],
        text: ""
      }));
    }

    this.status.loading = false;
  },

  components: {
    QuestionInput,
    Loader,
    QuizSubmit,
    QuizResult
  }
});
