






























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import EventCard from "@/components/EventCard.vue";
import ContentPlaceholder from "@/components/ContentPlaceholder.vue";
import Loader from "@/components/Loader.vue";
import { EventsType } from "@/types";

const { mapState, mapActions } = createNamespacedHelpers("event");

export default Vue.extend({
  data() {
    return {
      windowTop: 0,
      window
    };
  },

  computed: {
    ...mapState([
      "events",
      "status",
      "perPage",
      "eventsType",
      "currentPage",
      "lastPage",
      "getWindowTop"
    ]),

    loading(): boolean {
      return this.status.loading;
    },

    nextPage(): number {
      return this.currentPage < this.lastPage ? this.currentPage + 1 : null;
    },

    placeholder(): { icon: string; title: string; text: string } {
      const icon =
        this.eventsType === "enrolled"
          ? "mdi-qrcode"
          : this.eventsType === "passed"
          ? "mdi-help-circle-outline"
          : "";

      const title =
        this.eventsType === "enrolled"
          ? "Тут будуть з'являтися QR-коди"
          : this.eventsType === "passed"
          ? "Тут будуть з'являтися тести після заходів"
          : "";

      const text =
        this.eventsType === "enrolled"
          ? "Коли оператори ПКА будуть підтверджувати Вашу участь у офлайн заходах, тут Ви зможете знайти QR-коди для підтвердження Вашої участі."
          : this.eventsType === "passed"
          ? "Тут буде з'являтися інформація про заходи, які передбачають проходження тесту як обов'язкову умову для нарахування балів"
          : "";

      return { icon, title, text };
    }
  },

  methods: {
    ...mapActions(["fetchEvents", "setWindowTop"]),

    fetchNextPage() {
      this.fetchEvents(this.nextPage);
    },

    onScroll(e: Event) {
      this.windowTop = this.window.top.scrollY;
    }
  },

  mounted() {
    this.window.addEventListener("scroll", this.onScroll);
  },

  beforeDestroy() {
    this.window.removeEventListener("scroll", this.onScroll);
  },

  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.window.scroll(0, vm.getWindowTop);
    });
  },

  beforeRouteLeave(to, from, next) {
    this.setWindowTop({ windowTop: this.windowTop });
    next();
  },

  components: {
    EventCard,
    Loader,
    ContentPlaceholder
  }
});
