





































































































import Vue from "vue";
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
import FbButton from "@/components/FbButton.vue";
import GoogleButton from "@/components/GoogleButton.vue";
import { API_BASE_URL } from "@/config";

export default Vue.extend({
  components: {
    FbButton,
    GoogleButton
  },

  directives: { mask },

  props: {
    error: { type: Boolean, default: false },
    loginProvider: { type: String, required: true }
  },

  data: () => ({
    email: "",
    password: "",
    code: "",
    disabledChangeEmailBtnCount: 30,
    apiBaseUrl: API_BASE_URL
  }),

  computed: {
    ...mapState(["account"])
  },

  mounted() {
    this.setFocusOnEmailInput();
  },

  methods: {
    submit() {
      if (!this.account.status.codeSubmitted && !this.account.status.smsSent) {
        if (this.email && this.password) {
          this.$store.dispatch("account/submitEmail", {
            email: this.email,
            password: this.password
          });
          let interval = setInterval(() => {
            this.disabledChangeEmailBtnCount--;
            if (this.disabledChangeEmailBtnCount === 0) clearInterval(interval);
          }, 1000);
        }
      }
    },
    changeEmail() {
      this.$store.dispatch("account/resetFormToEmail", this.code);
      this.disabledChangeEmailBtnCount = 30;
      const el = this.$refs.emailForm as HTMLFormElement;
      el.reset();
      this.setFocusOnEmailInput();
    },
    setFocusOnEmailInput() {
      const el = this.$refs.email as HTMLElement;
      this.$nextTick(() => el.focus());
    }
  }
});
