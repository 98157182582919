














import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers("notification");

export default Vue.extend({
  computed: {
    ...mapState(["notifications"])
  },
  methods: {
    ...mapActions(["remove"])
  }
});
