


























import Vue from "vue";
import { QuestionResponse, ResponseOption } from "@/types";
import { CUSTOM_OPTION_TAG } from "@/config";
import SingleOptionInput from "@/components/SingleOptionInput.vue";
import MultipleOptionInput from "@/components/MultipleOptionInput.vue";
import TextAreaInput from "@/components/TextAreaInput.vue";

export default Vue.extend({
  data() {
    return {
      input: this.value as QuestionResponse
    };
  },

  props: {
    type: {
      type: String,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object
    }
  },

  methods: {
    onChange(val: QuestionResponse) {
      this.input = val;
      this.$emit("input", this.input);
    }
  },

  components: {
    SingleOptionInput,
    MultipleOptionInput,
    TextAreaInput
  },

  mounted() {
    this.$watch(
      "value",
      value => {
        this.input = this.value;
      },
      { immediate: true }
    );
  }
});
