









import Vue from "vue";
import NotificationContainer from "@/components/NotificationContainer.vue";
import AppBar from "@/components/AppBar.vue";
import NavBar from "@/components/NavBar.vue";
import { createNamespacedHelpers } from "vuex";
import store from "@/store";

const { mapState } = createNamespacedHelpers("account");

export default Vue.extend({
  name: "App",
  computed: {
    ...mapState(["status"])
  },

  components: {
    NotificationContainer,
    AppBar,
    NavBar
  },

  mounted() {
    store.dispatch("region/fetchRegions");
  }
});
