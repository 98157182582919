


































import Vue from "vue";
import { PRONOUNS } from "@/config";
import { Resource } from "@/types";

export default Vue.extend({
  props: {
    resource: {
      type: String,
      default: "Сторінка" as Resource
    }
  },

  computed: {
    pronoun(): string {
      return PRONOUNS[this.resource as Resource];
    }
  }
});
