





























import Vue from "vue";
import { Point } from "@/types";
import { format, parse } from "date-fns";

export default Vue.extend({
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    addedFormated(): string | null {
      if (this.value.added)
        return format(
          parse(this.value.added, "yyyy-MM-dd HH:mm:ss", new Date()),
          "dd.MM.yyyy"
        );
      return null;
    }
  },
  methods: {
    transChoicePoints(points: any) {
      if (points === 1) {
        return "бал";
      }
      if (points > 1 && points < 5) {
        return "бали";
      }
      return "балів";
    }
  }
});
