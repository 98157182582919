import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import Login from "@/views/Login.vue";
import LoginPhone from "@/views/LoginPhone.vue";
import Dashboard from "@/views/Dashboard.vue";
import Events from "@/views/Events.vue";
import Event from "@/views/Event.vue";
import EventEnrollment from "@/views/EventEnrollment.vue";
import Exam from "@/views/Exam.vue";
import QuizStart from "@/views/QuizStart.vue";
import QuizQuestions from "@/views/QuizQuestions.vue";
import Points from "@/views/Points.vue";
import Profile from "@/views/Profile.vue";
import NotFound from "@/views/NotFound.vue";
import Support from "@/views/Support.vue";
import store from "@/store";
import { EventsType } from "@/types";
import { EVENTS_PER_PAGE } from "@/config";

Vue.use(VueRouter);

const state = {
  profile: {
    error: false,
    message: ""
  }
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },

  {
    path: "/login",
    name: "login",
    props: (route: any) => ({
      error: route.query.error !== 0,
      loginProvider:
        route.query.account === "google"
          ? "Google"
          : route.query.account === "facebook"
          ? "Facebook"
          : ""
    }),
    component: Login,
    beforeEnter(to: Route, from: Route, next: Function) {
      const { sms, code } = to.query;
      if (sms && !code) {
        store.dispatch("account/resetFormToCode");
      }

      if (sms && code) {
        store.dispatch("account/submitCode", code);
      }

      next();
    }
  },

  {
    path: "/login-phone",
    name: "login-phone",
    props: (route: any) => ({
      error: route.query.error !== 0,
      loginProvider:
        route.query.account === "google"
          ? "Google"
          : route.query.account === "facebook"
          ? "Facebook"
          : ""
    }),
    component: LoginPhone
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/events",
    name: "events",
    component: Events,
    meta: {
      requiresAuth: true,
      eventsType: "active" as EventsType
    }
  },

  {
    path: "/events/:id",
    name: "event",
    component: Event,
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    path: "/events/:id/unenroll",
    name: "event-unenroll",
    component: EventEnrollment,
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    path: "/events/:id/enroll",
    name: "event-enroll",
    component: EventEnrollment,
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    path: "/exams",
    name: "exams",
    component: Events,
    meta: {
      requiresAuth: true,
      eventsType: "passed" as EventsType
    }
  },

  {
    path: "/exams/:id/start",
    name: "exam-start",
    component: QuizStart,
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    path: "/exams/:id/questions",
    name: "exam-questions",
    component: QuizQuestions,
    meta: {
      requiresAuth: true
    },
    props: { quizType: "exam" }
  },

  {
    path: "/feedbacks/:id/questions",
    name: "feedback-questions",
    component: QuizQuestions,
    meta: {
      requiresAuth: true
    },
    props: { quizType: "feedback" }
  },

  {
    path: "/feedbacks/:id/start",
    name: "feedback-start",
    component: QuizStart,
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    path: "/qr-codes",
    name: "qr-codes",
    component: Events,
    meta: {
      requiresAuth: true,
      eventsType: "enrolled" as EventsType
    }
  },

  {
    path: "/points",
    name: "points",
    component: Points,
    meta: {
      requiresAuth: true
    },

    beforeEnter(to: Route, from: Route, next: Function) {
      if ((store as any).state.point.points.length === 0) {
        store.dispatch("point/fetchPoints");
      }

      if ((store as any).state.point.years.length === 0) {
        store.dispatch("profile/fetchProfile");
      }

      next();
    }
  },

  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAuth: true
    },
    props: (route: any) => route.query
  },

  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/404",
    name: "404",
    component: NotFound,
    props: true
  },

  {
    path: "*",
    redirect: { name: "404" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const { loggedIn } = (store as any).state.account.status;

    if (loggedIn) {
      next();
    } else {
      next({ name: "login" });
    }
  }

  const { eventsType } = to.meta;
  if (eventsType && (<any>store.state).event.eventsType !== eventsType) {
    store.dispatch("event/setEventsType", { eventsType });
    store.dispatch("event/fetchEvents");
  }

  next();
});

export default router;
