import { Event, EventState, Events, EventsType, EventFilter } from "@/types";
import EventRepository from "@/repositories/EventRepository";
import { EVENTS_PER_PAGE } from "@/config";
import router from "@/router";
import LocalStorageService from "@/services/LocalStorageService";

export const namespaced = true;

export const state: EventState = {
  eventTotal: 0,
  windowTop: 0,
  perPage: EVENTS_PER_PAGE,
  currentPage: 1,
  lastPage: 0,
  eventsType: "",
  events: [],
  event: null,
  status: {
    loading: false,
    error: false
  },
  showFilter: false,
  filter: LocalStorageService.getEventFilter(),
  dates: [] as string[]
};

export const getters = {
  getEventById(state: EventState) {
    return (eventId: number) =>
      state.events.find(event => event.id === eventId);
  },

  filterNotEmty(state: EventState) {
    return Object.keys(state.filter).some(
      key => (state.filter as any)[key] !== undefined
    );
  }
};

export const mutations = {
  FETCH_EVENTS_REQUEST() {
    state.status.loading = true;
  },

  FETCH_EVENTS_FAIL() {
    state.status.error = true;
  },

  SET_LOADING_STATUS() {
    state.status.loading = true;
  },

  CLEAR_LOADING_STATUS() {
    state.status.loading = false;
  },

  FETCH_EVENTS_SUCCESS(state: EventState, payload: Events) {
    const { events, eventTotal, currentPage, lastPage, type } = payload;
    state.events = [...state.events, ...events];
    state.eventTotal = eventTotal;
    state.currentPage = currentPage;
    state.lastPage = lastPage;
    state.status.loading = false;
    state.status.error = false;
  },

  SET_EVENTS_TYPE(state: EventState, payload: { eventsType: EventsType }) {
    const { eventsType } = payload;
    state.eventsType = eventsType;
  },

  SET_WINDOW_TOP(state: EventState, payload: { windowTop: number }) {
    const { windowTop } = payload;
    state.windowTop = windowTop;
  },

  CLEAR_STATE() {
    state.eventTotal = 0;
    state.perPage = EVENTS_PER_PAGE;
    state.currentPage = 1;
    state.lastPage = 0;
    state.eventsType = "";
    state.events = [];
    state.event = null;
    state.status = {
      loading: false,
      error: false
    };
    state.showFilter = !state.showFilter;
    state.filter = {};
  },

  CLEAR_EVENTS() {
    state.events = [];
  },

  TOGGLE_FILTER(state: EventState) {
    state.showFilter = !state.showFilter;
  },

  SET_FILTER(state: EventState, payload: EventFilter) {
    state.filter = payload;
    state.showFilter = false;
    LocalStorageService.setEventFilter(payload);
  },

  SET_DATES(state: EventState, payload: string[]) {
    state.dates = payload;
  }
};

export const actions = {
  async fetchEvents({ commit }: { commit: Function }, page: number = 1) {
    commit("FETCH_EVENTS_REQUEST");

    const payload = await EventRepository.getEvents(
      state.eventsType,
      state.perPage,
      page,
      state.eventsType === "active" ? state.filter : {}
    )
      .catch(err => {
        commit("FETCH_EVENTS_FAIL");
      })
      .finally(() => {
        commit("CLEAR_LOADING_STATUS");
      });

    if (payload) {
      commit("FETCH_EVENTS_SUCCESS", payload);
    }
  },

  clearState({ commit }: { commit: Function }) {
    commit("CLEAR_STATE");
  },

  clearEvents({ commit }: { commit: Function }) {
    commit("CLEAR_EVENTS");
  },

  setEventsType(
    { commit }: { commit: Function },
    payload: { eventsType: EventsType }
  ) {
    commit("SET_EVENTS_TYPE", payload);
    commit("CLEAR_EVENTS");
  },

  setWindowTop(
    { commit }: { commit: Function },
    payload: { windowTop: number }
  ) {
    commit("SET_WINDOW_TOP", payload);
  },

  async fetchEvent({ commit }: { commit: Function }, payload: { id: number }) {
    const { id } = payload;
    const eventFromStore = state.events.find(event => event.id === id);

    if (eventFromStore) {
      return eventFromStore;
    }
    commit("SET_LOADING_STATUS");

    const event = await EventRepository.getEventById(id)
      .catch(err => {})
      .finally(() => {
        commit("CLEAR_LOADING_STATUS");
      });

    if (event) {
      return event;
    } else {
      router.replace({ name: "404", params: { resource: "Захід" } });
    }
  },

  toggleFilter({ commit }: { commit: Function }) {
    commit("TOGGLE_FILTER");
  },

  setFilter({ commit }: { commit: Function }, payload: EventFilter) {
    commit("CLEAR_EVENTS");
    commit("SET_FILTER", payload);
  },

  async fetchAllDates({ commit }: { commit: Function }) {
    const payload = await EventRepository.getAllEventsDates();

    if (payload) {
      commit("SET_DATES", payload);
    }
  }
};
