




























import Vue from "vue";
import { QuestionResponse, ResponseOption } from "@/types";
import { CUSTOM_OPTION_TAG } from "@/config";

export default Vue.extend({
  data() {
    return {
      selected: null as null | number,
      text: ""
    };
  },

  props: {
    value: {
      type: Object
    },
    options: {
      type: Array,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    }
  },

  computed: {
    hardOptions() {
      return (this.options as ResponseOption[]).filter(
        option => option.text !== CUSTOM_OPTION_TAG
      );
    },
    customOptionId() {
      const customOption = (this.options as ResponseOption[]).find(
        option => option.text === CUSTOM_OPTION_TAG
      );

      return customOption ? customOption.id : null;
    }
  },

  methods: {
    onRadioChange(val: number) {
      this.selected = val;
      this.$emit("input", this.getValueToEmit());
    },

    onTextChange(val: string) {
      this.text = val;
      this.selected = this.customOptionId;
      this.$emit("input", this.getValueToEmit());
    },

    getValueToEmit() {
      return {
        questionId: this.questionId,
        ...(this.selected ? { optionIds: [this.selected] } : {}),
        ...(this.text.length > 0 && this.selected === this.customOptionId
          ? { text: this.text, customOptionId: this.customOptionId }
          : {})
      };
    }
  },

  mounted() {
    this.selected =
      this.value && this.value.optionIds ? this.value.optionIds[0] : null;
    this.text = this.value.text ? this.value.text : "";
  }
});
