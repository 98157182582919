import { EventFilter, RefusedFeedback } from "@/types";

export default {
  setTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
  },

  getAccessToken() {
    return localStorage.getItem("access_token");
  },

  getRefreshToken() {
    return localStorage.getItem("refresh_token");
  },

  clearTokens() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  },

  setEventFilter(filter: EventFilter) {
    localStorage.setItem("eventFilter", JSON.stringify(filter));
  },

  getEventFilter(): EventFilter {
    const filter = localStorage.getItem("eventFilter");
    return filter ? JSON.parse(filter) : {};
  },

  getRefusedFeedbacks(): RefusedFeedback[] {
    const items = localStorage.getItem("refusedFeedbacks");
    if (items) {
      const feedbacks = JSON.parse(items) as any[];
      return feedbacks.map(item => {
        const { id, end } = item;
        return { id, end: new Date(end) };
      });
    }
    return [];
  },

  setRefusedFeedbacks(items: RefusedFeedback[]) {
    localStorage.setItem("refusedFeedbacks", JSON.stringify(items));
  },

  clearRefusedFeedbacks() {
    localStorage.removeItem("refusedFeedbacks");
  }
};
