




















import Vue from "vue";

export default Vue.extend({
  props: {
    points: {
      type: Number,
      required: true
    },
    ePoints: {
      type: Number,
      required: true
    },
    bPoints: {
      type: Number,
      required: true
    }
  },
  methods: {
    transChoicePoints(points: any) {
      if (points === 1) {
        return "бал";
      }
      if (points > 1 && points < 5) {
        return "бали";
      }
      return "балів";
    }
  }
});
