














import Vue from "vue";

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    }
  }
});
