


























import Vue from "vue";
import { format } from "date-fns";
import { mapState } from "vuex";

const TODAY = format(new Date(), "yyy-MM-dd");

export default Vue.extend({
  props: {
    value: { type: String, required: true }
  },

  data() {
    return {
      calendarVisible: false,
      date: this.value ? this.value : new Date().toISOString().substr(0, 10)
    };
  },

  mounted() {
    this.date = this.value;
  },

  computed: {
    ...mapState("event", ["dates"]),
    formattedDate(): string {
      return this.date ? format(new Date(this.date), "dd.MM.yyy") : "";
    }
  },

  methods: {
    onDateChange() {
      this.$emit("input", this.date);
    },

    allowedDates(date: string): boolean {
      return this.dates.includes(date);
    }
  }
});
