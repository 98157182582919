


















import Vue from "vue";
import { NAV_ITEMS } from "@/config";

export default Vue.extend({
  data() {
    return {
      items: NAV_ITEMS
    };
  },
  computed: {
    activeBtn() {
      return NAV_ITEMS.findIndex(item => item.path === this.$route.path);
    }
  },
  methods: {
    navigate(route: string) {
      if (route !== this.$route.path) {
        this.$router.replace(route);
      }
    }
  }
});
