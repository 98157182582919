

















import Vue from "vue";
import QuoteRepository from "@/repositories/QuoteRepository";
import { Quote } from "@/types";

export default Vue.extend({
  name: "QuoteCard",

  data() {
    return {
      quote: null as Quote | null
    };
  },

  async mounted() {
    this.quote = await QuoteRepository.getQuote();
  },

  methods: {
    onClick() {
      if (this.quote && this.quote.url) {
        window.open(this.quote.url);
      }
    },

    async onClose() {
      if (this.quote) {
        await QuoteRepository.markAsViewed(this.quote.id);
        this.quote = await QuoteRepository.getQuote();
      }
    }
  }
});
