import store from "@/store";
import { Notification } from "@/types";

export default {
  addNotification(type: string, message: string) {
    const notification = <Notification>{
      type,
      message
    };

    store.dispatch("notification/add", notification, { root: true });
  }
};
