import { Region } from "@/types";
import RegionService from "@/services/RegionService";

const mapper = (data: any) => {
  return Object.entries(data).map(([key, value]) => ({
    id: Number(key),
    name: value
  })) as Region[];
};

export default {
  async getAll(): Promise<Region[]> {
    const data = await RegionService.getRegions();
    const regions = mapper(data);
    return regions;
  }
};
