import Vue from "vue";
import Vuex from "vuex";
import * as account from "@/store/modules/account";
import * as notification from "@/store/modules/notification";
import * as profile from "@/store/modules/profile";
import * as event from "@/store/modules/event";
import * as point from "@/store/modules/point";
import * as support from "@/store/modules/support";
import * as dashboard from "@/store/modules/dashboard";
import * as region from "@/store/modules/region";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    notification,
    profile,
    event,
    point,
    support,
    dashboard,
    region
  }
});
