import LocalStorageService from "@/services/LocalStorageService";
import { RefusedFeedback, DashboardState } from "@/types";
import { isFeedbackDateExpired } from "@/helpers/DateHelper";

export const namespaced = true;

const refusedFeedbacks = LocalStorageService.getRefusedFeedbacks().filter(
  (feedback: RefusedFeedback) => !isFeedbackDateExpired(feedback.end)
);

LocalStorageService.setRefusedFeedbacks(refusedFeedbacks);

export const state: DashboardState = { refusedFeedbacks };

export const mutations = {
  ADD_REFUSED_FEEDBACK(state: DashboardState, feedback: RefusedFeedback) {
    const { id } = feedback;
    if (!state.refusedFeedbacks.some(feedback => feedback.id === id)) {
      state.refusedFeedbacks.push(feedback);
      LocalStorageService.setRefusedFeedbacks(state.refusedFeedbacks);
    }
  }
};

export const actions = {
  refuseFeedback({ commit }: { commit: Function }, feedback: RefusedFeedback) {
    commit("ADD_REFUSED_FEEDBACK", feedback);
  }
};

export const getters = {
  getRefusedFeedbackIds(state: DashboardState) {
    return state.refusedFeedbacks.map(feedback => feedback.id);
  }
};
