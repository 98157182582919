












































import Vue from "vue";

export default Vue.extend({
  props: {
    loading: { type: Boolean }
  }
});
