import QuoteService from "@/services/QuoteService";
import { Quote } from "@/types";

export default {
  async getQuote(): Promise<Quote> {
    const quotes = (await QuoteService.getQuotes()) as Quote[];

    return quotes[0];
  },

  async markAsViewed(id: number) {
    await QuoteService.markAsViewed(id);
  }
};
