
































































import Vue from "vue";
import TextAreaInput from "@/components/TextAreaInput.vue";
import { createNamespacedHelpers } from "vuex";
import { Route } from "vue-router";

const { mapState, mapActions } = createNamespacedHelpers("support");

export default Vue.extend({
  data() {
    return {
      message: "",
      file: null
    };
  },
  computed: {
    ...mapState(["text", "status", "progress"])
  },

  methods: {
    ...mapActions(["setText", "submit", "clearState"]),

    onSubmit() {
      this.submit({
        text: this.message,
        ...(this.file ? { file: this.file } : {})
      });
    },

    onFileChange(ev: any) {
      this.file = ev;
    },

    validate(message: string): string | boolean {
      if (message.length === 0) {
        return "Не можливо надіслати порожнє повідомлення";
      }

      if (message.length >= 1000) {
        return "Не можливо надіслати повідомлення довше 1000 символів";
      }

      return true;
    }
  },

  mounted() {
    this.message = this.$store.state.support.text;
  },

  beforeRouteLeave(to: Route, from: Route, next: Function) {
    if (this.status.success) {
      this.clearState();
    } else {
      this.setText({ text: this.message });
    }
    next();
  }
});
