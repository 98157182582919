

























































































































































import Vue from "vue";
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
import FbButton from "@/components/FbButton.vue";
import GoogleButton from "@/components/GoogleButton.vue";
import { API_BASE_URL } from "@/config";

export default Vue.extend({
  components: {
    FbButton,
    GoogleButton
  },

  directives: { mask },

  props: {
    error: { type: Boolean, default: false },
    loginProvider: { type: String, required: true }
  },

  data: () => ({
    phone: "",
    code: "",
    disabledChangePhoneBtnCount: 30,
    apiBaseUrl: API_BASE_URL
  }),

  computed: {
    ...mapState(["account"]),
    phoneRules(): string | boolean {
      return (
        /^\+38\s\(0(39|50|63|66|67|68|73|91|92|93|94|95|96|97|98|99)\)\s\d{3}-\d{4}$/.test(
          this.phone
        ) || "потрібен коректний мобільний номер"
      );
    },
    codeRules(): string | boolean {
      return /^\d{6}$/.test(this.code) || "потрібен код із 6 цифр";
    }
  },

  mounted() {
    this.setFocusOnPhoneInput();
  },

  methods: {
    submit() {
      if (
        !this.account.status.codeSubmitted &&
        !this.account.status.smsSent &&
        this.phoneRules === true
      ) {
        if (this.phone) {
          this.$store.dispatch(
            "account/submitPhone",
            this.phone.replace(/\D*/g, "")
          );
          let interval = setInterval(() => {
            this.disabledChangePhoneBtnCount--;
            if (this.disabledChangePhoneBtnCount === 0) clearInterval(interval);
          }, 1000);
        }
      } else if (this.codeRules === true) {
        this.$store.dispatch("account/submitCode", this.code);
      }
    },
    changePhone() {
      this.$store.dispatch("account/resetFormToPhone", this.code);
      this.disabledChangePhoneBtnCount = 30;
      const phoneForm = this.$refs.phoneForm as HTMLFormElement;
      const codeForm = this.$refs.codeForm as HTMLFormElement;
      phoneForm.reset();
      codeForm.reset();
      this.setFocusOnPhoneInput();
    },
    setFocusOnPhoneInput() {
      const phoneElement = this.$refs.phone as HTMLElement;
      this.$nextTick(() => phoneElement.focus());
    },
    setFocusOnCodeInput() {
      const codeElement = this.$refs.code as HTMLElement;
      this.$nextTick(() => codeElement.focus());
    }
  },
  updated() {
    if (this.account.status.codeSubmitted || this.account.status.smsSent) {
      this.setFocusOnCodeInput();
    }
  }
});
