
























































import Vue from "vue";
import ProgressRepository from "@/repositories/ProgressRepository";
import { Progress } from "@/types";

export default Vue.extend({
  data() {
    return {
      progress: null as Progress | null
    };
  },

  computed: {
    pointsValue(): number {
      return this.progress
        ? Math.ceil(
            (Math.min(
              this.progress.required.points,
              this.progress.current.points
            ) /
              this.progress.required.points) *
              100
          )
        : 0;
    },

    ePointsValue(): number {
      return this.progress
        ? Math.ceil(
            (Math.min(
              this.progress.required.e_points,
              this.progress.current.e_points
            ) /
              this.progress.required.e_points) *
              100
          )
        : 0;
    },

    bPointsValue(): number {
      return this.progress
        ? Math.ceil(
            (Math.min(
              this.progress.required.b_points,
              this.progress.current.b_points
            ) /
              this.progress.required.b_points) *
              100
          )
        : 0;
    },

    completed(): boolean {
      return this.progress
        ? this.progress.current.points >= this.progress.required.points 
          && this.progress.current.e_points >= this.progress.required.e_points
          && (this.progress.required.b_points == 0 || this.progress.current.b_points >= this.progress.required.b_points)
        : false;
    }
  },

  async mounted() {
    const progress = await ProgressRepository.getProgress().catch(err => {});
    if (progress) {
      this.progress = progress;
    }
  }
});
