import { QuizType, Question, QuestionResponse, ExamResult } from "@/types";
import QuizService from "@/services/QuizService";

export default {
  async getByTypeAndId(type: QuizType, eventId: number): Promise<Question[]> {
    const data = await QuizService.getQuiz(type, eventId);

    if (type === "exam") {
      return data.questions as Question[];
    } else {
      return data as Question[];
    }
  },

  async addResponse(
    type: QuizType,
    eventId: number,
    responses: QuestionResponse[]
  ): Promise<ExamResult> {
    let questions;

    if (type === "exam") {
      questions = responses.map(response => {
        return {
          id: response.questionId,
          options: response.optionIds
        };
      });
    } else {
      questions = responses.map(response => {
        return {
          id: response.questionId,
          options: response.optionIds.map(optionId => {
            return {
              id: optionId,
              text: optionId === response.customOptionId ? response.text : null
            };
          })
        };
      });
    }

    return QuizService.postResponse(type, eventId, { questions });
  }
};
