import { SupportState } from "@/types";
import SupportRepository from "@/repositories/SupportRepository";
import NotificationService from "@/services/NotificationService";

export const namespaced = true;

export const state: SupportState = {
  text: "",
  status: {
    loading: false,
    error: false,
    success: false
  },
  progress: null
};

export const mutations = {
  SET_TEXT(state: SupportState, payload: { text: string }) {
    state.text = payload.text;
  },

  CLEAR_STATE() {
    state.text = "";
    state.status.error = false;
    state.status.loading = false;
    state.status.success = false;
    state.progress = null;
  },

  SUBMIT_REQUEST(state: SupportState, payload: { text: string }) {
    state.text = payload.text;
    state.status.loading = true;
  },

  SUBMIT_FAIL() {
    state.status.error = true;
    state.status.loading = false;
  },

  SUBMIT_SUCCESS() {
    state.status.loading = false;
    state.status.success = true;
    state.text = "";
  },

  SET_PROGRESS(state: SupportState, payload: { progress: number }) {
    state.progress = payload.progress;
  }
};

export const actions = {
  clearState({ commit }: { commit: Function }) {
    commit("CLEAR_STATE");
  },

  async submit(
    { commit }: { commit: Function },
    payload: { text: string; file?: File }
  ) {
    const { text, file } = payload;
    if (file) {
      commit("SET_PROGRESS", { progress: 0 });
    }

    await commit("SUBMIT_REQUEST", payload);

    const success = await SupportRepository.addMessage(text, file);

    if (success) {
      commit("SUBMIT_SUCCESS");

      NotificationService.addNotification(
        "success",
        "Ваше повідомлення надіслано"
      );
    } else {
      commit("SUBMIT_FAIL");

      NotificationService.addNotification(
        "error",
        "Щось пішло не так. Ми не змогли надіслати Ваше повідомлення"
      );
    }

    if (file) {
      commit("SET_PROGRESS", { progress: null });
    }
  },

  setText({ commit }: { commit: Function }, payload: { text: string }) {
    commit("SET_TEXT", payload);
  },

  setProgress({ commit }: { commit: Function }, payload: { progress: number }) {
    commit("SET_PROGRESS", payload);
  }
};
