import { ExamResult, QuizType } from "@/types";
import apiClient from "@/helpers/ApiHelper";
import router from "@/router";
import NotificationService from "@/services/NotificationService";

export default {
  async getQuiz(type: QuizType, eventId: number) {
    const response: any = await apiClient
      .get(`api/events/${eventId}/${type}`)
      .catch(err => {
        // handle error
        router.push(`/events/${eventId}`);
      });

    if (response && response.status === 200) {
      return response.data;
    }
  },

  async postResponse(
    type: QuizType,
    eventId: number,
    payload: any
  ): Promise<ExamResult> {
    const result = {
      passed: false,
      failedQuestionIds: [] as number[],
      message: "" as string
    } as ExamResult;

    const response: any = await apiClient
      .post(`api/events/${eventId}/${type}`, payload)
      .catch(err => {
        result.failedQuestionIds = [...err.response.data.invalid];
        result.passed = false;

        if (err.response.status === 422 && type === "exam") {
          if (
            err.response.data.message &&
            result.failedQuestionIds.length < 1
          ) {
            result.message = err.response.data.message;
            NotificationService.addNotification(
              "error",
              err.response.data.message
            );
          } else {
            NotificationService.addNotification(
              "error",
              "Ви не відповіли на всі питання правильно. Спробуйте ще раз"
            );
          }
        }
      });

    if (response && response.status === 200) {
      result.passed = true;
    }

    return result;
  }
};
