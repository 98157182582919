


















import Vue from "vue";
import { EventCardActions } from "@/types";

interface EventCardBtn {
  action: EventCardActions;
  text: string;
  outlined: boolean;
}

const EVENT_CARD_BTNS: EventCardBtn[] = [
  {
    action: "enroll",
    text: "Взяти участь",
    outlined: false
  },
  {
    action: "qrcode",
    text: "QR код",
    outlined: false
  },
  {
    action: "join",
    text: "Взяти участь",
    outlined: false
  },
  {
    action: "unenroll",
    text: "Скасувати участь",
    outlined: true
  },
  {
    action: "exam",
    text: "Пройти тест",
    outlined: false
  },
  {
    action: "feedback",
    text: "Залишити відгук",
    outlined: true
  }
];

export default Vue.extend({
  props: {
    actions: { type: Object as () => Record<EventCardActions, boolean> },
    fullView: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btns(): EventCardBtn[] {
      return EVENT_CARD_BTNS.filter(btn => this.actions[btn.action]);
    }
  }
});
