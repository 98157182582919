import AccountService from "@/services/AccountService";
import localStorageService from "@/services/LocalStorageService";

import { AccountState, LoginProvider } from "@/types";
import router from "@/router";
import NotificationService from "@/services/NotificationService";
import store from "..";

export const namespaced = true;

const accessToken = localStorageService.getAccessToken();
const refreshToken = localStorageService.getRefreshToken();

export const state: AccountState =
  accessToken && refreshToken
    ? { status: { loggedIn: true }, accessToken, refreshToken }
    : { status: {} };

export const mutations = {
  SUBMIT_EMAIL(state: AccountState, email: string) {
    state.status = { emailSubmitted: true };
  },

  SUBMIT_PHONE(state: AccountState, phone: string) {
    state.status = { phoneSubmitted: true };
  },

  SMS_SENT(state: AccountState) {
    state.status = { smsSent: true };
  },

  SUBMIT_CODE(state: AccountState) {
    state.status = { codeSubmitted: true };
  },

  SAVE_TOKENS(
    state: AccountState,
    { accessToken, refreshToken }: { accessToken: string; refreshToken: string }
  ) {
    state.status = { loggedIn: true };
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;
  },

  LOG_OUT(state: AccountState) {
    state.status = {};
    delete state.accessToken;
    delete state.refreshToken;
  },

  CLEAR_STATUS(state: AccountState) {
    state.status = {};
  },

  SET_STATUS_SMS_SENT(state: AccountState) {
    state.status = { smsSent: true };
  }
};

export const actions = {
  async submitEmail(
    { commit }: { commit: Function },
    payload: { email: string; password: string }
  ) {
    const { email, password } = payload;
    commit("SUBMIT_EMAIL", email);
    const response = await AccountService.logInEmail(email, password);
    if (response && response.data) {
      const {
        access_token: accessToken,
        refresh_token: refreshToken
      } = response.data;
      if (accessToken && refreshToken) {
        commit("SAVE_TOKENS", { accessToken, refreshToken });

        localStorageService.setTokens(accessToken, refreshToken);

        router.push({ name: "dashboard" });
      }
    } else {
      NotificationService.addNotification(
        "error",
        "Профіля адвоката з таким e-mail або паролем не існує"
      );
      store.dispatch("account/resetFormToEmail", { root: true });
    }
  },

  async submitPhone({ commit }: { commit: Function }, phone: string) {
    commit("SUBMIT_PHONE", phone);
    const response = await AccountService.logIn(phone);
    if (response && response.status) {
      commit("SMS_SENT");
    }
  },

  async submitCode({ commit }: { commit: Function }, code: string) {
    commit("SUBMIT_CODE");
    const response = <
      void | { data: { access_token: string; refresh_token: string } }
    >await AccountService.getToken(code);

    if (response && response.data) {
      const {
        access_token: accessToken,
        refresh_token: refreshToken
      } = response.data;
      if (accessToken && refreshToken) {
        commit("SAVE_TOKENS", { accessToken, refreshToken });

        localStorageService.setTokens(accessToken, refreshToken);

        router.push({ name: "dashboard" });
      }
    }
  },

  resetFormToEmail({ commit }: { commit: Function }) {
    commit("CLEAR_STATUS");
  },

  resetFormToPhone({ commit }: { commit: Function }) {
    commit("CLEAR_STATUS");
  },

  resetFormToCode({ commit }: { commit: Function }) {
    commit("SET_STATUS_SMS_SENT");
  },

  logOut({ commit }: { commit: Function }) {
    commit("LOG_OUT");
    localStorageService.clearTokens();
    router.push({ name: "login" });
  }
};
